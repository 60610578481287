import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const NextSeasonAnnouncement: React.FC = () => {
  return (
    <>
      <Header />
      <main className="py-12 pt-32 max-w-screen-2xl mx-auto">
        <div className="max-w-3xl mx-auto px-8 md:px-16">
          <h1 className="font-serif text-4xl md:text-6xl relative inline-block mb-8">
            A Special Announcement from the Atlantic Music Festival
          </h1>
          <p className="text-lg leading-relaxed tracking-wide text-[#1D3557]/80 mb-6">
            The Atlantic Music Festival is pleased to announce that its 16th season will take place in the summer of 2026.
            The festival is undertaking significant developments that will shape an exciting new chapter in AMF's history.
          </p>
          <p className="text-lg leading-relaxed tracking-wide text-[#1D3557]/80 mb-6">
            This preparatory period will be dedicated to enriching the festival experience and expanding artistic opportunities,
            building upon AMF's tradition of musical excellence. These foundational changes will position the festival for
            continued growth and innovation in the years ahead.
          </p>
          <p className="text-lg leading-relaxed tracking-wide text-[#1D3557]/80 mb-6">
            Our commitment to nurturing exceptional musical talent and bringing transformative performances to the stage
            remains steadfast. We look forward to sharing more details about our plans for 2026 in the coming months.
          </p>
          <p className="text-lg leading-relaxed tracking-wide text-[#1D3557]/80 mb-6">
            The Atlantic Music Festival extends its deepest gratitude to our entire community for their continued support.
            We can't wait to welcome you back for what promises to be a remarkable season.
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NextSeasonAnnouncement;
