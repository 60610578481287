export const APPLICATION_CONFIG = {
  status: {
    isOpen: false, // Set to false since it's before December 15
    message: "Applications for the 2026 season will open on September 15, 2025"
  },
  deadlines: {
    start: "2025-09-15",
    phases: [
      {
        deadline: "2025-11-15",
        label: "Submit by November 15, 2025",
        fees: {
          student: 50,
          fellowship: 75
        }
      },
      {
        deadline: "2026-01-01",
        label: "Submit by January 1, 2026",
        fees: {
          student: 75,
          fellowship: 100
        }
      },
      {
        deadline: "2025-03-01", // After this date
        label: "After January 1, 2026",
        fees: {
          student: 100,
          fellowship: 125
        }
      }
    ]
  },
  fees: {
    currency: 'usd',
    getCurrentFees: () => {
      const now = new Date();
      for (const phase of APPLICATION_CONFIG.deadlines.phases) {
        if (now <= new Date(phase.deadline)) {
          return phase.fees;
        }
      }
      // Return last phase fees if all deadlines passed
      return APPLICATION_CONFIG.deadlines.phases[APPLICATION_CONFIG.deadlines.phases.length - 1].fees;
    },
    getCurrentPhase: () => {
      const now = new Date();
      for (const phase of APPLICATION_CONFIG.deadlines.phases) {
        if (now <= new Date(phase.deadline)) {
          return phase;
        }
      }
      return APPLICATION_CONFIG.deadlines.phases[APPLICATION_CONFIG.deadlines.phases.length - 1];
    }
  },
  stripe: {
    publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    allowedParameters: [
      'lineItems',
      'mode',
      'successUrl',
      'cancelUrl',
      'customerEmail',
      'sessionId'
    ] as const
  }
} as const;
