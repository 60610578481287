import React from 'react';
import { ArrowRight } from 'lucide-react';
import { NavigationSection, NavigationLink } from '../../types/navigation';
import { SITE_CONFIG } from '../../config/constants';
import { APPLICATION_CONFIG } from '../../config/application';
import { formatDate } from '../../utils/date';

interface MegaMenuProps {
  item: {
    label: string;
    items?: NavigationSection[];
  };
  variant?: 'light' | 'dark';
}

const CONFIG = {
  styles: {
    wrapper: "absolute hidden group-hover:block pt-6 left-0 w-[750px]",
    content: "bg-white p-8 shadow-sm border border-[#1D3557]/5",
    row: {
      wrapper: "space-y-8",
      divider: "border-t border-[#1D3557]/10 pt-8"
    },
    programs: {
      grid: "grid grid-cols-2 gap-12",
      section: {
        wrapper: "space-y-6",
        title: "text-sm text-[#457B9D] font-medium tracking-wide",
        link: "block group",
        linkTitle: "text-lg font-serif text-[#1D3557] hover:text-[#E63946] transition-colors",
        list: "mt-6 space-y-2"
      }
    },
    item: {
      link: "block text-lg font-serif text-[#1D3557] hover:text-[#E63946] transition-colors py-1"
    },
    footer: {
      wrapper: "flex items-center justify-between mt-8 pt-8 border-t border-[#1D3557]/10",
      deadline: {
        label: "text-[10px] tracking-[0.2em] text-[#1D3557]/40",
        date: "block text-sm font-light mt-1 text-[#1D3557]"
      },
      button: {
        wrapper: "relative overflow-hidden group",
        container: "block px-8 py-3 border-2 border-[#1D3557]",
        text: "relative z-10 flex items-center gap-2 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const MegaMenu: React.FC<MegaMenuProps> = ({ item, variant = 'light' }) => {
  if (!item?.items?.length) return null;

  const isPrograms = item.label === 'Programs';
  if (!isPrograms) return null;

  const [programsSection, fellowshipSection] = item.items;
    const applicationOpenDate = formatDate(APPLICATION_CONFIG.deadlines.start, 'MMMM dd, yyyy');

  return (
    <div className={CONFIG.styles.wrapper}>
      <div className={CONFIG.styles.content}>
        <div className={CONFIG.styles.row.wrapper}>
          {/* Programs and Fellowships */}
          <div className={CONFIG.styles.programs.grid}>
            {/* Left Column: Programs */}
            {programsSection && (
              <div className={CONFIG.styles.programs.section.wrapper}>
                <h4 className={CONFIG.styles.programs.section.title}>
                  Pre-College Program
                </h4>
                <a 
                  href="/the-institute/pre-college" 
                  className={CONFIG.styles.programs.section.link}
                >
                  <h4 className={CONFIG.styles.programs.section.linkTitle}>
                    Pre-College Program
                  </h4>
                  <p className="text-sm text-[#1D3557]/60">
                    Summer intensive for exceptional young musicians ages 14-18
                  </p>
                </a>

                <h4 className={CONFIG.styles.programs.section.title + " mt-8"}>
                  Collegiate & Professional Programs
                </h4>
                <div className={CONFIG.styles.programs.section.list}>
                  <a href="/the-institute/programs/composition-program" className={CONFIG.styles.item.link}>
                    Composition Program
                  </a>
                  <a href="/the-institute/programs/conducting-program" className={CONFIG.styles.item.link}>
                    Conducting Program
                  </a>
                  <a href="/the-institute/programs/instrumental-program" className={CONFIG.styles.item.link}>
                    Instrumental Program
                  </a>
                  <a href="/the-institute/programs/piano-institute-and-seminar" className={CONFIG.styles.item.link}>
                    Piano Institute
                  </a>
                  <a href="/the-institute/programs/vocal-institute" className={CONFIG.styles.item.link}>
                    Vocal Arts Institute
                  </a>
                </div>
              </div>
            )}

            {/* Right Column: Fellowship Programs and Resources */}
            <div className="space-y-8">
              {/* Fellowship Programs */}
              <div>
                <h4 className={CONFIG.styles.programs.section.title}>
                  Fellowship Programs
                </h4>
                <div className={CONFIG.styles.programs.section.list}>
                  {fellowshipSection.items.map((item: NavigationLink, idx: number) => (
                    <a key={idx} href={item.url} className={CONFIG.styles.item.link}>
                      {item.label}
                    </a>
                  ))}
                </div>
              </div>

              {/* Resources */}
              <div>
                <h4 className={CONFIG.styles.programs.section.title}>
                  Resources
                </h4>
                <div className={CONFIG.styles.programs.section.list}>
                  <a href="/the-institute/online-application" className={CONFIG.styles.item.link}>
                    Online Application
                  </a>
                  <a href="/the-institute/financial-aid" className={CONFIG.styles.item.link}>
                    Financial Aid
                  </a>
                  <a href="/the-institute/residency-guidelines" className={CONFIG.styles.item.link}>
                    Residency Guidelines
                  </a>
                  <a href="/the-institute/fees-dates-and-deadlines" className={CONFIG.styles.item.link}>
                    Fees & Deadlines
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className={CONFIG.styles.footer.wrapper}>
            <div>
              <span className={CONFIG.styles.footer.deadline.label}>
                APPLICATION OPENS
              </span>
              <span className={CONFIG.styles.footer.deadline.date}>
                {applicationOpenDate}
              </span>
            </div>
            <a href="/the-institute/online-application" className={CONFIG.styles.footer.button.wrapper}>
              <span className={CONFIG.styles.footer.button.container}>
                <span className={CONFIG.styles.footer.button.text}>
                  Apply Now
                  <ArrowRight size={14} className="group-hover:translate-x-2 transition-transform duration-500" />
                </span>
              </span>
              <div className={CONFIG.styles.footer.button.background} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
