export const FESTIVAL_CONFIG = {
  season: {
    year: 2026,
    fullSession: {
      dates: "June 29 – August 9, 2026",
      arrival: {
        date: "June 29, 2026",
        time: "2:00 PM"
      },
      departure: {
        date: "August 9, 2026",
        time: "10:00 AM"
      }
    },
    sessions: [
      {
        id: 1,
        name: "Session 1",
        dates: "June 29 – July 19, 2026"
      },
      {
        id: 2,
        name: "Session 2",
        dates: "July 20 – August 9, 2026"
      }
    ],
    concerts: {
      opening: {
        date: "July 4, 2026",
        time: "7:00 PM",
        title: "AMF Orchestra Opening Concert"
      },
      closing: {
        date: "August 8, 2026",
        time: "7:00 PM",
        title: "AMF Orchestra Season Finale"
      }
    }
  },
  tuition: {
    fullSession: {
      student: 8700,
      fellowship: "Full Coverage",
      duration: "6 weeks",
      notes: [
        "Complete program participation required",
        "No late arrivals or early departures"
      ]
    },
    singleSession: {
      student: 4950,
      fellowship: "Not Available",
      duration: "3 weeks",
      notes: [
        "Choose one session period"
      ]
    },
    additionalFees: {
      registration: {
        student: 925,
        fellowship: 750,
        note: "Applied to final tuition (students) or refundable upon completion (fellows)"
      },
      comprehensive: {
        student: 350,
        fellowship: 350,
        note: "Covers event insurance and administrative costs"
      },
      damage: {
        student: 500,
        fellowship: "Included in registration",
        note: "Refundable after successful program completion"
      }
    }
  }
} as const;
