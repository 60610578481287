import React from 'react';
import { APPLICATION_CONFIG } from '../../config/application';

const CONFIG = {
  title: "Application Fees",
  description: "Non-refundable fees required with application submission.",
  note: null, // "2023 applicants using the same account are eligible for fee waiver.",
  styles: {
    table: {
      wrapper: "w-full border-collapse",
      header: "bg-[#1D3557] text-white text-left p-4",
      cell: "border-b border-[#1D3557]/10 p-4",
      amount: "font-medium"
    }
  }
};

const ApplicationFees = () => {
  return (
    <div>
      <h2 className="font-serif text-4xl mb-6">{CONFIG.title}</h2>
      <p className="text-lg text-[#1D3557]/80 mb-8">{CONFIG.description}</p>
      
      <table className={CONFIG.styles.table.wrapper}>
        <thead>
          <tr>
            <th className={CONFIG.styles.table.header}>Status</th>
            <th className={CONFIG.styles.table.header}>Student Candidate</th>
            <th className={CONFIG.styles.table.header}>Fellowship & Student Candidate</th>
          </tr>
        </thead>
        <tbody>
          {APPLICATION_CONFIG.deadlines.phases.map((phase, index) => (
            <tr key={index}>
              <td className={CONFIG.styles.table.cell}>{phase.label}</td>
              <td className={CONFIG.styles.table.cell}>
                <span className={CONFIG.styles.table.amount}>${phase.fees.student}</span>
              </td>
              <td className={CONFIG.styles.table.cell}>
                <span className={CONFIG.styles.table.amount}>${phase.fees.fellowship}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {CONFIG?.note && <p className="text-[#457B9D] mt-4 italic">{CONFIG.note}</p>}
    </div>
  );
};

export default ApplicationFees;