import React, { createContext, useContext, ReactNode } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FESTIVAL_CONFIG } from '../config/festival';

interface Announcement {
  id: string;
  message: ReactNode;
  variant?: 'light' | 'dark';
  excludedPaths?: string[];
}

interface AnnouncementContextType {
  announcements: Announcement[];
}

const AnnouncementContext = createContext<AnnouncementContextType | undefined>(undefined);

// Current active announcements
const ACTIVE_ANNOUNCEMENTS: Announcement[] = [
  {
    id: '16th-season-announcement',
    message: (
      <>
        A special announcement regarding our upcoming seasons.{' '}
        <Link to="/16th-season-announcement" className="text-white underline">
          Read more
        </Link>
      </>
    ),
    variant: 'dark',
    excludedPaths: ['/16th-season-announcement'],
  },
  {
    id: 'season-2025',
    message: `You are viewing information for the previous season. Information for the 2026 season (${FESTIVAL_CONFIG.season.fullSession.dates}) will be announced soon.`,
    variant: 'light',
    excludedPaths: ['/', '/16th-season-announcement'],
  },
];

export const useAnnouncements = () => {
  const context = useContext(AnnouncementContext);
  const location = useLocation();

  if (!context) {
    throw new Error('useAnnouncements must be used within an AnnouncementProvider');
  }

  // Filter announcements based on exact current path
  const visibleAnnouncements = context.announcements.filter(announcement => {
    if (!announcement.excludedPaths) return true;
    if (announcement.excludedPaths.includes('*')) {
      return location.pathname === '/';
    }
    return !announcement.excludedPaths.includes(location.pathname);
  });

  return { announcements: visibleAnnouncements };
};

export const AnnouncementProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <AnnouncementContext.Provider value={{ announcements: ACTIVE_ANNOUNCEMENTS }}>
      {children}
    </AnnouncementContext.Provider>
  );
};
