import React from 'react';

const SeasonOverview = () => {
  return (
    <section className="py-32 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-4xl mx-auto">
          <h2 className="font-serif text-7xl mb-16">Season Overview</h2>
          
          <div className="prose prose-lg prose-headings:font-serif prose-p:text-[#1D3557]/70 prose-a:text-[#E63946] prose-a:no-underline hover:prose-a:underline space-y-12">
            <p className="text-2xl leading-relaxed first-letter:text-8xl first-letter:font-serif first-letter:text-[#E63946] first-letter:float-left first-letter:mr-4 first-letter:mt-1">
              The Atlantic Music Festival Piano Institute and Seminar is an exciting summer opportunity for conservatory-level pianists. Participants can choose to attend one or both sessions, each lasting three weeks, for a total of six weeks of intensive musical training.
            </p>
            
            <div className="pl-8 border-l-2 border-[#E63946]/20">
              <p className="text-xl leading-relaxed">
                With a focus on individualized instruction, pianists will receive personalized feedback and guidance from experienced instructors. The program also offers opportunities for solo and ensemble performances and provides archival recordings, helping pianists to develop their skills and showcase their abilities.
              </p>
            </div>
            
            <p className="text-xl leading-relaxed">
              In addition to the core piano curriculum, students can participate in cross-discipline seminars offered by other programs at the festival, including Instrumental Program, Composition Program, Vocal Arts Institute, and MUGIC Lab, which includes electronic music and motion sensor workshops. These seminars provide pianists with a well-rounded musical education, allowing them to expand their knowledge and skills in a supportive and collaborative environment.
            </p>
            
            <div className="bg-[#1D3557]/[0.02] p-8 rounded-lg">
              <p className="text-xl leading-relaxed !mb-0">
                The Atlantic Music Festival provides a beautiful setting for pianists to immerse themselves in their craft, make lasting connections with like-minded musicians, and gain a life-changing musical experience. The comprehensive curriculum and supportive atmosphere will equip pianists with the tools and confidence to enter the world of concert pianists.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeasonOverview;