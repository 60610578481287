import { ROUTES } from './routes';
import { getRouteUrl } from '../utils/navigation';

// Helper to create navigation items with route-derived URLs
const createNavItem = (label: string, path: string, description?: string) => ({
  label,
  url: getRouteUrl(path),
  ...(description ? { description } : {})
});

export const NAVIGATION_ITEMS = [
  {
    label: 'Programs',
    items: [
      {
        section: "Programs",
        items: [
          createNavItem(
            "Pre-College Program",
            "/the-institute/pre-college",
            "Summer intensive for exceptional young musicians ages 14-18"
          ),
          {
            label: "Collegiate & Professional",
            category: "header",
            url: '#'
          },
          createNavItem(
            "Composition Program",
            "/the-institute/programs/composition-program",
            "Advanced training in contemporary composition"
          ),
          createNavItem(
            "Conducting Program",
            "/the-institute/programs/conducting-program",
            "Intensive conducting studies with renowned mentors"
          ),
          createNavItem(
            "Instrumental Program",
            "/the-institute/programs/instrumental-program",
            "Chamber music and orchestral performance opportunities"
          ),
          createNavItem(
            "Piano Institute & Seminar",
            "/the-institute/programs/piano-institute-and-seminar",
            "Comprehensive piano training and performance"
          ),
          createNavItem(
            "Vocal Arts Institute",
            "/the-institute/programs/vocal-institute",
            "Comprehensive vocal training and performance"
          )
        ]
      },
      {
        section: "Fellowship Programs",
        items: [
          createNavItem(
            "Resident Artist Fellowship",
            "/the-institute/programs/fellowship-program"
          ),
          createNavItem(
            "Contemporary Ensemble",
            "/the-institute/programs/fellowship-program/contemporary-ensemble"
          )
        ]
      }
    ]
  },
  {
    label: 'Concerts',
    url: getRouteUrl('/concerts')
  },
  {
    label: 'About',
    items: [
      {
        section: "About",
        items: [
          createNavItem("About AMF", "/the-festival/about-amf"),
          createNavItem("Welcome", "/the-festival/welcome"),
          createNavItem("Artists", "/the-festival/artists"),
          createNavItem("Diversity & Inclusion", "/the-festival/diversity")
        ]
      },
      {
        section: "Visit",
        items: [
          createNavItem("Visitor Information", "/visitors"),
          createNavItem("Contact Us", "/contact-us")
        ]
      }
    ]
  },
  {
    label: 'Get Involved',
    items: [
      {
        section: "Support",
        items: [
          createNavItem("Make a Gift", "/get-involved"),
          createNavItem("Volunteer", "/get-involved/volunteer"),
          createNavItem("Jobs", "/get-involved/jobs"),
          createNavItem("Internships", "/get-involved/jobs/internship")
        ]
      }
    ]
  }
];
