export const calculateAge = (birthDate: string): number => {
  const today = new Date();
  const birth = new Date(birthDate);
  
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  
  return age;
};

export const formatDate = (date: string, format: string): string => {
  const dateObj = new Date(date + 'T00:00:00Z');
  return dateObj.toLocaleDateString('en-US', {
    timeZone: 'UTC',
    year: format.includes('yyyy') ? 'numeric' : undefined,
    month: format.includes('MMMM') ? 'long' : format.includes('MM') ? '2-digit' : undefined,
    day: format.includes('dd') ? '2-digit' : undefined,
  });
};
