import { getAssetUrl, IMAGE_SIZES } from '../../utils/directus';
import { APPLICATION_CONFIG } from '../../config/application';

export const COMPOSITION_PROGRAM_DATA = {
  hero: {
    imageId: '82b7760f-fe82-4ed4-ba94-d620443fedc4',
    title: 'Composition Program',
    slogan: 'Join a dynamic and supportive community of innovative composers for a transformative six-week experience in the art of composition.',
    description: 'Discover your place in the world of composition and become part of a legacy that is shaping the future of classical music.',
    stats: [
      { number: '40+', label: 'World Premieres' },
      { number: '40+', label: 'Composers per Season' },
      { number: '3/6', label: 'Week Sessions' }
    ]
  },

  features: [
    {
      title: 'In-Depth Exploration',
      description: 'Dive into the world of composition with access to individual lessons, daily seminars, and masterclasses. Gain valuable insights into the creative process and refine your craft under the guidance of master composers.',
      imageId: 'f8764cd0-e456-42e0-b8c4-46ee8df79f32',
      color: '#457B9D'
    },
    {
      title: 'Premiere Performance',
      description: 'Each composer will write a piece for the festival, with the opportunity to have it premiered during a dedicated new music concert. An archival recording of the performance will be provided at the conclusion.',
      imageId: '5f430d44-005a-415a-afea-a28adfcafc84',
      color: '#E63946'
    },
    {
      title: 'Orchestra Reading',
      description: 'Full-session participants are offered a chance to take part in AMF Orchestra Reading. This is an exceptional opportunity to hear a live rendition of an orchestral work and receive valuable feedback.',
      imageId: 'b0b4ecd8-d037-480f-b11b-cc70ffb37eeb',
      color: '#F6BD60'
    }
  ],

  tracks: [
    {
      title: "Traditional Composition",
      description: "Focus on acoustic composition for chamber and orchestral ensembles. Work closely with our resident ensembles and receive mentorship from distinguished faculty members throughout both sessions.",
      image: getAssetUrl("5f430d44-005a-415a-afea-a28adfcafc84", IMAGE_SIZES.feature)
    },
    {
      title: "Electronic & Mixed Media",
      description: "Available in Session 1, explore electronic music and interactive technology through our MUGIC™ Lab. Learn programming, MaxMSP, and motion sensor integration in this unique course that began as an official collaboration with IRCAM.",
      image: getAssetUrl("16969daf-a600-46ca-aca7-13e385ecc2ce", IMAGE_SIZES.feature)
    },
    {
      title: "Screen Scoring",
      description: "Available in Session 2, study the art of scoring for film and media with NYU Screen Scoring faculty. Master virtual orchestration, editing, and industry practices while working with real film footage.",
      image: getAssetUrl("ab95d01b-8dae-407f-8373-6097376c5c8f", IMAGE_SIZES.feature)
    }
  ],

  opportunities: [
    {
      title: "Session 1: June 30 - July 21",
      description: "Focus on traditional composition and electronic music through our MUGIC™ Lab. Work with resident ensembles, participate in masterclasses, and explore cutting-edge technology in computer music.",
      icon: "calendar"
    },
    {
      title: "Session 2: July 21 - August 10",
      description: "Continue with traditional composition while having the opportunity to study screen scoring with NYU faculty. Learn industry practices and work with real film footage.",
      icon: "calendar"
    },
    {
      title: "Full Session Benefits",
      description: "Participate in both sessions to maximize your experience. Full-session participants receive the unique opportunity to participate in the AMF Orchestra Reading program.",
      icon: "star"
    },
    {
      title: "Performance & Recording",
      description: "Each composer receives one chamber music premiere during the festival season, performed by our resident ensembles and professionally recorded for your portfolio.",
      icon: "music"
    }
  ],

  application: {
    title: "Apply Now",
    description: "Join us for a transformative summer of musical creation and discovery. Applications are now being accepted for the upcoming season.",
    requirements: [
      "Two recent compositions (scores and recordings if available)",
      "Statement of artistic goals",
      "Letter of recommendation"
    ],
    deadline: APPLICATION_CONFIG.deadlines.phases[0].deadline
  }
};
