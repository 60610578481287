import { getAssetUrl } from '../utils/directus';

export const SITE_CONFIG = {
  applicationDeadline: {
    date: "September 15, 2025",
    label: "APPLICATION OPENS"
  },
  logos: {
    default: 'ef0b75cf-6898-4d70-8996-f0548af19f82',
    dark: 'e84c48bf-6313-4e30-8b27-cf86d2d5a2b0'
  },
  colors: {
    text: {
      light: "text-[#1D3557]",
      dark: "text-white"
    },
    hover: {
      light: "hover:text-[#E63946]",
      dark: "hover:text-[#F6BD60]"
    }
  }
};

export const getLogoUrl = (id: string) => {
  return getAssetUrl(id, {
    quality: 100
  });
};